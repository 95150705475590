import useProduct from '@/views/product/useProduct'
import ProductModal from '@/modal/productModal'
import {
  ref, reactive, watch, computed,
} from '@vue/composition-api'
import util from '@/global/util'
import store from '@/store'
import http from '@/global/http'
import constant from '@/global/const'

export default function useProductDetail() {
  const {
    actions,
    productMenuType,
    productMenuTypeVI,
    //
    fetchProduct,
    fetchSuggestedListCustomer,
    closeDealProduct,
    openDealProduct,
    refreshProduct,
  } = useProduct()
  const today = new Date()
  const closeDealSignAtMonth = ref(today.getMonth() + 1)
  const closeDealSignAtYear = ref(today.getFullYear())
  const closeDealExpireMonth = ref('')
  const closeDealExpireYear = ref('')
  const closeDealData = ref({
    isNotHaveInfoExpired: null,
    expireMonth: '',
    expireYear: '',
    expire_month: '',
  })
  const suggestedList = ref([])
  const fitFilter = reactive({
    is_not_exported: ref(''),
    user_id: ref(''),
  })
  const product = ProductModal()
  const tableColumnsSuggestedCustomer = [
    {
      label: 'Mã',
      key: 'id',
      tdClass: 'text-primary minw-80',
    },
    {
      label: 'Tiêu đề',
      key: 'title',
      tdClass: 'py-1 minw-250',
    },
    {
      label: 'Khách hàng',
      key: 'host_name',
      tdClass: 'position-relative',
    },
    {
      label: 'Người đăng',
      key: 'user.fullname',
      tdClass: 'text-nowrap',
    },
    {
      label: 'Kết cấu',
      key: 'ket-cau',
      formatter: (value, key, item) => ([
        item.width && item.length ? `${item.width}x${item.length}m` : '',
        item.basement ? '1 hầm, ' : '',
        ' 1 trệt,',
        item.badger ? '1 lửng, ' : '',
        item.floor_number ? `${item.floor_number} lầu` : '',
        item.terrace ? 'ST' : '',
      ].join('')),
    },
    {
      label: 'Giá tổng',
      key: 'gia-tong',
      tdClass: 'text-nowrap',
      formatter: (value, key, item) => {
        let { price } = item
        if (item.currency.toLowerCase() === 'million') {
          price = item.price / 1000000
        }
        if (item.currency.toLowerCase() === 'billion') {
          price = item.price / 1000000000
        }
        return `${price} ${(constant.CURRENCY.find(c => c.value === (item.currency || '')).text || '').toUpperCase()}`
      },
    },
  ]

  const routeParams = ref({})
  const totalSuggestedList = ref(0)
  const perPage = ref(10)
  const perPageOptions = [10, 25, 50, 100]
  const currentPage = ref(1)

  const users = computed(() => store.state.app.users)

  const fetchSuggestedList = (id, cb) => fetchSuggestedListCustomer({
    id: id || routeParams.value.id,
    q: http.buildQuery({
      page: currentPage.value - 1,
      perpage: perPage.value,
      ...fitFilter,
    }),
  }, res => {
    suggestedList.value = res.data.result || []
    totalSuggestedList.value = res.data.total || 0
    return cb && cb(res)
  })

  watch(fitFilter, () => {
    fetchSuggestedList()
  })

  watch([currentPage, perPage], () => {
    fetchSuggestedList()
  })

  watch([closeDealExpireYear, closeDealExpireMonth, closeDealSignAtMonth, closeDealSignAtYear], () => {
    const d = new Date(`${closeDealSignAtYear.value || today.getFullYear()}/${closeDealSignAtMonth.value || (today.getMonth() + 1)}`)
    const ey = Number.parseInt(d.getFullYear(), 10) + (Number.parseInt(closeDealExpireYear.value, 10) || 0)
    const em = Number.parseInt(d.getMonth() + 1, 10) + (Number.parseInt(closeDealExpireMonth.value, 10) || 0)
    product.expire_contract_date = new Date(ey, em).toISOString()
  })

  watch(() => closeDealData.value.isNotHaveInfoExpired, value => {
    if (value) {
      closeDealData.value.expireMonth = ''
      closeDealData.value.expireYear = ''
    }
  })

  return {
    product,
    suggestedList,
    productMenuType,
    productMenuTypeVI,
    actions,
    fitFilter,
    tableColumnsSuggestedCustomer,
    perPage,
    perPageOptions,
    users,
    routeParams,
    totalSuggestedList,
    currentPage,
    closeDealData,
    closeDealExpireYear,
    closeDealExpireMonth,
    closeDealSignAtMonth,
    closeDealSignAtYear,
    //
    fetchProduct,
    closeDealProduct,
    openDealProduct,
    refreshProduct,
    fetchSuggestedList,
  }
}
